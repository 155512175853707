<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('detail')" :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <Header-mobile :title="$t('detail')" :is-filter="false"/>
            </template>
            <div>
                <b-row class="mx-1">
                    <b-col md="5" class="border rounded p-4 mb-3">
                        <h6 class="mt-1 mb-3">{{ this.$t('university_informations') }}</h6>
                        <b-row>
                            <b-col sm="12">
                                <b-form-group :label="$t('student_number')">
                                    {{ student.student_number }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12">
                                <b-form-group :label="$t('faculty')">
                                    {{ student.faculty_name }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12">
                                <b-form-group :label="$t('program')">
                                    {{ student.program_name }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12">
                                <b-form-group :label="$t('class')">
                                    {{ student.class }}
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col md="6" offset-md="1" class="border rounded p-4 mb-3">
                        <h6 class="mt-1 mb-3">{{ $t('personal_info') }}</h6>
                        <b-row>
                            <b-col sm="12">
                                <b-form-group :label="$t('name')">
                                    {{ getObjectValue(student,'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12">
                                <b-form-group :label="$t('surname')">
                                    {{ getObjectValue(student,'surname') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12">
                                <b-form-group :label="$t('gsm_no')">
                                    {{ getObjectValue(student,'mobile_tel') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12">
                                <b-form-group :label="$t('email')">
                                    {{ getObjectValue(student,'email') }}
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <h6 class="mt-1 mb-3">{{ $t('personal_info') }}</h6>
                <div class="border rounded p-4 mb-3">
                    <datatable
                        :rows="datatable.rows"
                        :columns="datatable.columns"
                        :query-params="datatable.queryParams"
                        v-show="datatable.showTable"
                        :is-loading="datatable.isLoading"
                    >
                    </datatable>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import Datatable from "@/components/datatable/Datatable";

import WithDrawService from "@/services/WithDrawService";

export default {
    components: {
        Datatable, HeaderMobile, Header, AppLayout
    },
    data() {
        return {
            trainee: {},
            student: {},
            datatable: {
                columns: [
                    {field: "semester", label: this.toUpperCase("semester"), hidden: false, sortable: false},
                    {field: "slot_type", label: this.toUpperCase("slot_type"), hidden: false, sortable: false},
                    {field: "course_code", label: this.toUpperCase("course_code"), hidden: false, sortable: false},
                    {field: ("course_name"), label: this.toUpperCase("course_name"), hidden: false, sortable: false},
                    {field: "credit", label: this.toUpperCase("credit"), hidden: false, sortable: false},
                ],
                showTable: false,
                isLoading: false,
                rows: [],
                queryParams: {},
            },

        }
    },
    created() {
        this.getRows()
    },
    methods: {
        getRows() {

            this.datatable.isLoading = true;
            this.datatable.showTable = true;


            WithDrawService.show(this.$route.params.id)
                .then((res) => {
                        this.student = res.data.data
                        this.datatable.rows = [{...this.student.student_course,semester:this.student.student_course.semester.semester}]
                    }
                ).catch(e => this.showErrors(e))
                .finally(() => this.datatable.isLoading = false)
        },

    },
}
</script>
